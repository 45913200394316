import Image, { ImageProps } from 'next/image';

import { BLUR_URL } from '@/utils/images';
import { useState } from 'react';

interface Props extends ImageProps {
  errSrc?: string;
  disable?: boolean;
}

const CustomImage = ({ errSrc = BLUR_URL, disable, ...props }: Props) => {
  const { src, ...etc } = props;

  const [err, setErr] = useState(false);

  if (disable && err) return null;
  return (
    <Image src={err ? errSrc : src} onError={() => setErr(true)} {...etc} />
  );
};

export default CustomImage;
