'use client';

import {
  MainNewsInDesktop,
  MainNewsInMobile,
} from '@/features/Main/components/MainNews';

import { Grid } from '@mantine/core';
import MainBlog from './components/MainBlog';
import MainConference from './components/MainConference';
import MainDiscovery from '@/features/Main/components/MainDiscovery';
import MainInput from '@/features/Main/components/MainInput';
import MainPoint from './components/MainPoint';
import { mixpanelTrack } from '@/utils/mixpanel';
import { useEffect } from 'react';
import { useMainStyles } from './hook/main.style';
import { sendGTMEvent } from '@next/third-parties/google';

function MainPage({ discovery }) {
  const { classes } = useMainStyles({});

  useEffect(() => {
    mixpanelTrack('MainPage', 'Viewed');
    sendGTMEvent({ event: 'page__view' });
  }, []);

  return (
    <div className={classes.mainPageContainer}>
      <MainInput />
      <Grid columns={5} gutter={32}>
        <Grid.Col md={3}>
          <MainNewsInMobile />
          <MainBlog />
          <MainDiscovery discovery={discovery} />
        </Grid.Col>
        <Grid.Col md={2}>
          <MainNewsInDesktop />
          <MainPoint />
          <MainConference />
        </Grid.Col>
      </Grid>
    </div>
  );
}

export default MainPage;
