import DMText from '@/components/DMText';
import themeVariables from '@/styles/themeVariables';

const MainTitle = ({ title, navigate, hover = false }) => {
  return (
    <DMText
      dColor={themeVariables.darkMode.normal}
      weight={600}
      sx={[
        (theme) => ({
          cursor: 'pointer',
          paddingTop: hover ? 32 : 0,
          paddingLeft: hover ? 32 : 0,
          [theme.fn.smallerThan('sm')]: {
            paddingTop: hover ? 24 : 0,
            paddingLeft: hover ? 20 : 0,
          },
        }),
        themeVariables.xsize.heading1,
      ]}
      onClick={navigate}
    >
      {title}
    </DMText>
  );
};

export default MainTitle;
