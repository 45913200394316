import { Flex, Stack, Text } from '@mantine/core';

import Image from 'next/image';
import { images } from '@/utils/images';
import themeVariables from '@/styles/themeVariables';
import { useRouter } from 'next/navigation';

const MainPoint = () => {
  const { push } = useRouter();

  return (
    <Flex
      bg={themeVariables.colors.blue300}
      mb={32}
      p={32}
      justify={'space-between'}
      sx={{ borderRadius: 12, cursor: 'pointer' }}
      onClick={() => push('/notice')}
    >
      <Stack spacing={10}>
        <Text weight={600} color="white" sx={themeVariables.xsize.heading2}>
          코드너리에서 이용할 수 있는
          <br />
          새로운 기능
        </Text>
        <Text
          weight={600}
          color={themeVariables.line.normal}
          mb={10}
          sx={themeVariables.xsize.label1}
        >
          지금 확인해 보세요!
        </Text>
      </Stack>
      <Image width={120} height={120} src={images.pointing} alt="point" />
    </Flex>
  );
};

export default MainPoint;
