import { Box, Divider, Flex, Group, Space, Stack, Text } from '@mantine/core';
import { addRefParam, dateToString, isDark } from '@/utils/utils';

import { API_ENDPOINT } from '@/config/endpoint';
import BottomTab from '@/features/Main/components/BottomTab';
import Card from '@/components/Card';
import DMText from '@/components/DMText';
import { IconExternalLink } from '@tabler/icons-react';
import MainTitle from './MainTitle';
import { mixpanelTrack } from '@/utils/mixpanel';
import themeVariables from '@/styles/themeVariables';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';

// const COLORS = [
//   '#f9f6fe',
//   '#fef4e3',
//   '#f7fcec',
//   '#fefcea',
//   '#f5f8fe',
//   '#f9f0f0',
// ];

const MainConference = () => {
  const { dark } = isDark();
  const { push } = useRouter();

  const { data }: any = useQuery(['main', 'conference'], () =>
    API_ENDPOINT.calendar.getSchedule(
      dateToString(new Date(), 'YYYY'),
      dateToString(new Date(), 'MM')
    )
  );

  const filter = data?.data?.filter(
    (item) =>
      +dateToString(new Date(), 'DD') - +dateToString(item?.start_date, 'DD') <=
        0 &&
      +dateToString(item?.end_date, 'DD') - +dateToString(new Date(), 'DD') >= 0
  );

  const group = filter?.slice(0, 4)?.reduce((a, b, idx) => {
    const date = +dateToString(b?.start_date, 'DD');

    if (a[date] === undefined) {
      a[date] = [];
    }

    a[date] = [...a[date], { ...b, num: idx }];
    return a;
  }, {});

  const navigateCalendar = () => {
    push('/calendar/month');
  };

  return (
    <Card noShadow style={{ padding: 0 }}>
      <Box
        p={32}
        sx={(theme) => ({
          [theme.fn.smallerThan('sm')]: {
            padding: '24px 20px',
          },
        })}
      >
        <MainTitle title={'이달의 컨퍼런스'} navigate={navigateCalendar} />
        <Space h={24} />
        {Object.entries(group || {}).map(([key, value]: any, dateIdx) => {
          return (
            <Box key={key}>
              <Flex gap={16}>
                <DMText
                  w={50}
                  weight={600}
                  sx={[themeVariables.xsize.headline1]}
                  dColor={themeVariables.darkMode.normal}
                >
                  {key}일
                </DMText>
                <Stack spacing={0} mt={6} display={'flex'}>
                  <Box
                    w={9}
                    h={9}
                    sx={{
                      borderRadius: '50%',
                      backgroundColor: `rgba(51, 133, 255, 1)`,
                    }}
                  />
                  <Divider
                    orientation="vertical"
                    h={'100%'}
                    mx={'auto'}
                    color={themeVariables.line.alternative}
                  />
                </Stack>
                <Stack spacing={0} w={'100%'} mt={6}>
                  {value?.map((eventInfo, valueIdx) => {
                    return (
                      <Stack
                        key={eventInfo?.id}
                        spacing={4}
                        bg={'rgba(51, 133, 255, 0.05)'}
                        py={18}
                        px={20}
                        mb={
                          dateIdx === Object.entries(group || {})?.length - 1 &&
                          valueIdx === value?.length - 1
                            ? 0
                            : valueIdx === value?.length - 1
                            ? 24
                            : 12
                        }
                        sx={{ borderRadius: 14 }}
                      >
                        <Text
                          weight={500}
                          lineClamp={2}
                          sx={[
                            themeVariables.xsize.body1,
                            {
                              color: dark
                                ? 'white'
                                : themeVariables.label.normal,
                            },
                          ]}
                        >
                          {eventInfo.title}
                        </Text>
                        <Text
                          sx={[
                            themeVariables.xsize.label1,
                            {
                              color: dark
                                ? themeVariables.label.normalDark
                                : themeVariables.label.normal,
                            },
                          ]}
                        >
                          {eventInfo.organization}
                        </Text>
                        <Group
                          spacing={4}
                          ml={'auto'}
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            mixpanelTrack(
                              'CalendarMonthPage_EventDetailPopOver_LinkButton',
                              'Clicked',
                              { id: eventInfo.id }
                            );
                            window.open(addRefParam(eventInfo.link), '_blank');
                          }}
                        >
                          <IconExternalLink
                            size={17}
                            color={
                              dark
                                ? themeVariables.label.alternativeDark
                                : themeVariables.label.alternative
                            }
                          />
                          <Text
                            weight={500}
                            color={
                              dark
                                ? themeVariables.label.alternativeDark
                                : themeVariables.label.alternative
                            }
                            sx={themeVariables.xsize.label1}
                          >
                            바로가기
                          </Text>
                        </Group>
                      </Stack>
                    );
                  })}
                </Stack>
              </Flex>
            </Box>
          );
        })}
      </Box>
      <BottomTab title={'컨퍼런스 일정 더 보기'} navigate={navigateCalendar} />
    </Card>
  );
};

export default MainConference;
