import { DISCOVERY_CONTENTS_TYPE } from '@/consts/Discovery';
import { isModalNumber } from '../discovery.state';
import { mixpanelTrack } from '@/utils/mixpanel';
import { useAtom } from 'jotai';
import { useReplaceQueryParams } from '@/utils/utils';
import { useRouter } from 'next/navigation';

export const initModalState = () => {
  const { queryParams } = useReplaceQueryParams();

  const category = queryParams.get('category');
  const typeList = queryParams.get('type')
    ? queryParams.get('type')?.split(',')
    : [DISCOVERY_CONTENTS_TYPE.video, DISCOVERY_CONTENTS_TYPE.text];

  return { typeList: typeList || [], category: category || '추천' };
};

export const useGoContentDetail = () => {
  const { replace } = useRouter();

  const [, setNumber] = useAtom(isModalNumber);

  const goDetailContent = (item) => (e) => {
    e.preventDefault();

    setNumber(item.id);
  };

  const goDetailRelatedContent = (item) => (e) => {
    e.preventDefault();

    mixpanelTrack('DiscoveryDetailModal_RelatedCard', 'Clicked', {
      id: item.id,
    });

    setNumber(item.id);
    replace(`/discoveries/${item.id}`);
  };

  return {
    goDetailContent,
    goDetailRelatedContent,
  };
};
