import { API_ENDPOINT } from '@/config/endpoint';
import { atom } from 'jotai';
import { useQuery } from '@tanstack/react-query';

export const useGetNews = () => {
  return useQuery(['news'], () => API_ENDPOINT.news.getNewsList(), {
    staleTime: 1000 * 60 * 10,
  });
};

export const useGetSearchRecommendKeywords = () => {
  return useQuery(
    ['search', 'recommended-keywords'],
    () => API_ENDPOINT.search.getSearchRecommendKeywords(),
    {
      staleTime: 60000,
    }
  );
};

export const useGetHints = (hintSearch) => {
  return useQuery(
    ['hints', hintSearch],
    async () => await API_ENDPOINT.search.getHints(hintSearch),
    {
      select: (data) => data,
      keepPreviousData: true,
      enabled: !!hintSearch,
    }
  );
};

export const newsIndex = atom(0);
