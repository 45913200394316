import { Box, Divider, Skeleton, Space, Stack, Text } from '@mantine/core';
import { Fragment, useEffect } from 'react';
import { isDark, timeForToday } from '@/utils/utils';
import { newsIndex, useGetNews } from '@/features/Main/hook/api';

import BottomTab from '@/features/Main/components/BottomTab';
import Card from '@/components/Card';
import DMText from '@/components/DMText';
import Link from 'next/link';
import MainTitle from './MainTitle';
import { mixpanelTrack } from '@/utils/mixpanel';
import themeVariables from '@/styles/themeVariables';
import { useRouter } from 'next/navigation';
import { useScrollInit } from '@/utils/hook';
import { useSetAtom } from 'jotai';

const MainNewsInDesktop = () => {
  const { push } = useRouter();

  const navigateNews = () => {
    push('/latest-news');
  };

  return (
    <Card
      noShadow
      style={(theme) => ({
        padding: 0,
        marginBottom: 32,
        [theme.fn.smallerThan('sm')]: {
          display: 'none',
        },
      })}
    >
      <Box>
        <MainTitle title={'테크 뉴스'} navigate={navigateNews} hover />
        <Space h={4} />
        <NewsList />
      </Box>
      <BottomTab title={'테크 뉴스 더 보기'} navigate={navigateNews} />
    </Card>
  );
};

const MainNewsInMobile = () => {
  const { push } = useRouter();

  const navigateNews = () => {
    push('/latest-news');
  };

  return (
    <Card
      noShadow
      style={(theme) => ({
        padding: 0,
        marginBottom: 32,
        display: 'none',
        width: '100%',
        [theme.fn.smallerThan('sm')]: {
          display: 'inline-block',
        },
      })}
    >
      <Box>
        <MainTitle title={'테크 뉴스'} navigate={navigateNews} hover />
        <Space h={12} />
        <NewsList />
      </Box>
      <BottomTab title={'테크 뉴스 더 보기'} navigate={navigateNews} />
    </Card>
  );
};

export { MainNewsInDesktop, MainNewsInMobile };

const NewsList = () => {
  const { dark } = isDark();
  const { data, isLoading } = useGetNews();

  useEffect(() => {
    useScrollInit();
  }, []);

  if (isLoading) {
    return (
      <Stack spacing={12} my={20}>
        {Array(5)
          .fill(0)
          .map((_, idx) => (
            <Skeleton w={'calc(100% - 64px)'} h={20} key={idx} mx={'auto'} />
          ))}
      </Stack>
    );
  }

  return (
    <Stack spacing={0}>
      {data?.items?.slice(0, 5).map((item, idx) => {
        return (
          <Fragment key={item.id}>
            <NewsContent item={item} />
            {idx !== 4 && (
              <Divider
                color={themeVariables.lineDark.alternative(dark)}
                sx={(theme) => ({
                  margin: 'auto',
                  width: 'calc(100% - 64px)',
                  [theme.fn.smallerThan('sm')]: {
                    width: 'calc(100% - 40px)',
                  },
                })}
              />
            )}
          </Fragment>
        );
      })}
    </Stack>
  );
};

const NewsContent = ({ item }) => {
  const { dark } = isDark();

  const setNewsIdx = useSetAtom(newsIndex);

  const navigateNewsDetail = () => {
    setNewsIdx(item.id);
    mixpanelTrack('MainPage_NewsCard', 'Clicked', {
      id: item.id,
    });
  };

  return (
    <Link href={`/latest-news`} id={item.id + ''} onClick={navigateNewsDetail}>
      <Box
        sx={(theme) => ({
          ':hover': {
            '#news-title': {
              color: themeVariables.label.alternative,
            },
          },
          padding: '20px 32px',
          [theme.fn.smallerThan('sm')]: {
            padding: '20px',
          },
        })}
      >
        <Text
          lineClamp={2}
          id={'news-title'}
          color={
            item.status === 'IMPORTANT'
              ? dark
                ? themeVariables.colors.blue200
                : themeVariables.colors.blue400
              : dark
              ? '#fff'
              : themeVariables.label.normal
          }
          weight={500}
          sx={themeVariables.xsize.body1}
        >
          {item.title}
        </Text>
        <Space h={4} />
        <DMText
          dColor={themeVariables.darkMode.alternative}
          sx={[themeVariables.xsize.label1]}
        >
          {timeForToday(item.published_at, false)}
        </DMText>
      </Box>
    </Link>
  );
};
