import { BLUR_URL, imageOpt, images } from '@/utils/images';
import { Box, Flex, Grid, Space, createStyles } from '@mantine/core';

import BottomTab from '@/features/Main/components/BottomTab';
import Card from '@/components/Card';
import CustomImage from '@/components/CustomImage';
import DMText from '@/components/DMText';
import Link from 'next/link';
import MainTitle from './MainTitle';
import { memo } from 'react';
import { mixpanelTrack } from '@/utils/mixpanel';
import themeVariables from '@/styles/themeVariables';
import { useGoContentDetail } from '@/features/Discovery/hook/useContentListMethod';
import { useRouter } from 'next/navigation';

const MainDiscovery = ({ discovery }) => {
  const { push } = useRouter();
  const { goDetailContent } = useGoContentDetail();

  const handleCard = (content) => (e) => {
    goDetailContent(content)(e);
    mixpanelTrack('DiscoveryListPage_Card', 'Clicked', {
      id: content?.id,
      techstacks: content?.techstacks?.map((techstack) => techstack.id),
    });
  };

  const navigateDiscovery = () => {
    push('/discoveries');
  };

  return (
    <Card noShadow style={{ padding: 0 }}>
      <Box
        p={32}
        sx={(theme) => ({
          [theme.fn.smallerThan('sm')]: {
            padding: '24px 20px',
          },
        })}
      >
        <MainTitle title={'디스커버리'} navigate={navigateDiscovery} />
        <Space h={30} />
        <Grid columns={3} gutter={22}>
          {discovery?.contents?.slice(0, 6)?.map((item, index) => (
            <Grid.Col key={index} sm={1} xs={1.5} onClick={handleCard(item)}>
              <Content item={item} />
            </Grid.Col>
          ))}
        </Grid>
      </Box>
      <BottomTab
        title={'디스커버리 콘텐츠 더 보기'}
        navigate={navigateDiscovery}
      />
    </Card>
  );
};

export default MainDiscovery;

const Content = memo(function Content({ item }: any) {
  const { classes } = useDiscoveryContentCardStyle();

  return (
    <Link href={`/discoveries/${item?.id}`}>
      <Box
        w={'100%'}
        sx={{
          overflow: 'hidden',
          '&:hover': {
            '#card-image': {
              transform: 'scale(1.1)',
            },
          },
        }}
      >
        <div className={classes.thumbnailWrapper}>
          <CustomImage
            id="card-image"
            fill
            src={imageOpt(item?.thumbnail, 400) || BLUR_URL}
            errSrc={BLUR_URL}
            style={{
              borderRadius: 16,
              border: `1px solid ${themeVariables.line.border}`,
              objectFit: 'cover',
              transition: '300ms all ease',
            }}
            priority
            alt="thumbnail"
          />
        </div>
      </Box>
      <Space h={16} />
      <DMText
        lineClamp={2}
        weight={500}
        dColor={themeVariables.darkMode.normal}
        sx={themeVariables.xsize.body1}
      >
        {item?.title}
      </DMText>
      <Space h={12} />
      <Flex gap={8} align={'center'}>
        <CustomImage
          width={20}
          height={20}
          src={item?.user?.image_url || BLUR_URL}
          errSrc={images.defaultProfile}
          alt="user_profile"
          style={{
            borderRadius: '50%',
            border: `1px solid ${themeVariables.line.border05}`,
          }}
        />
        <DMText
          dColor={themeVariables.darkMode.alternative}
          sx={[themeVariables.xsize.label1]}
        >
          {item?.user?.username}
        </DMText>
      </Flex>
    </Link>
  );
});

const useDiscoveryContentCardStyle = createStyles((theme) => ({
  thumbnailWrapper: {
    position: 'relative',
    width: '100%',
    height: 160,
    overflow: 'hidden',
    borderRadius: 16,
    [theme.fn.smallerThan('md')]: {
      height: 150,
    },
  },
}));
