import { Box, Divider, Flex, Space, Stack } from '@mantine/core';
import { isDark, timeForToday } from '@/utils/utils';

import BottomTab from '@/features/Main/components/BottomTab';
import { COMPANY_LOGO_ENDPOINT } from '@/config/endpoint';
import Card from '@/components/Card';
import CustomImage from '@/components/CustomImage';
import DMText from '@/components/DMText';
import { Fragment } from 'react';
import MainTitle from './MainTitle';
import { imageOpt } from '@/utils/images';
import { mixpanelTrack } from '@/utils/mixpanel';
import themeVariables from '@/styles/themeVariables';
import { useGetTechblogList } from '@/features/Techblog/hook/api';
import { useRouter } from 'next/navigation';

const MainBlog = () => {
  const { push } = useRouter();
  const { dark } = isDark();

  const { data } = useGetTechblogList({
    page: 1,
    keyword: '',
    tag: '',
    company: '',
  });

  const navigateTechBlog = () => {
    push('/techblog/list');
  };

  const navigateCompanyBlog = (id) => () => {
    push(`/techblog/list?company_id=${id}`);
  };

  const navigateBlogDetail = (techblog) => () => {
    mixpanelTrack('MainPage_TechblogCard', 'Clicked', {
      id: techblog.id,
      techstacks: techblog.tag?.split(','),
    });

    window.open(techblog.link);
  };

  return (
    <Card
      noShadow
      style={{
        padding: 0,
        marginBottom: 32,
      }}
    >
      <Box>
        <MainTitle title={'기술 블로그'} navigate={navigateTechBlog} hover />
        <Space h={12} />
        <Stack spacing={0}>
          {data?.slice(0, 6).map((item, idx) => (
            <Fragment key={item.id}>
              <Flex
                sx={(theme) => ({
                  position: 'relative',
                  cursor: 'pointer',
                  borderRadius: 4,
                  padding: '20px 32px',
                  gap: 20,
                  transition: '0.2s all ease',
                  ':hover #title': {
                    color: themeVariables.label.alternative,
                  },
                  [theme.fn.smallerThan('sm')]: {
                    padding: '20px',
                    gap: 12,
                  },
                })}
                onClick={navigateBlogDetail(item)}
              >
                <Box>
                  <Flex
                    pos={'relative'}
                    sx={(theme) => ({
                      width: 48,
                      height: 48,
                      border: `1px solid ${themeVariables.line.border08}`,
                      borderRadius: 10,
                      overflow: 'hidden',
                      [theme.fn.smallerThan('sm')]: {
                        width: 40,
                        height: 40,
                      },
                    })}
                  >
                    <CustomImage
                      fill
                      src={imageOpt(
                        `${COMPANY_LOGO_ENDPOINT}/${item.company_id}.png`,
                        100
                      )}
                      alt="logo"
                      style={{
                        objectFit: 'contain',
                        backgroundColor: 'white',
                      }}
                      onClick={navigateCompanyBlog(item.company_id)}
                    />
                  </Flex>
                </Box>
                <Stack spacing={0}>
                  <DMText
                    id={'title'}
                    weight={500}
                    lineClamp={1}
                    dColor={themeVariables.darkMode.normal}
                    sx={themeVariables.xsize.headline1}
                  >
                    {item.title}
                  </DMText>
                  <Space h={4} />
                  <DMText
                    lineClamp={1}
                    dColor={themeVariables.darkMode.neutral}
                    sx={themeVariables.xsize.body1}
                  >
                    {item.summary}
                  </DMText>
                  <Space h={12} />
                  <Flex align={'center'}>
                    <DMText
                      sx={[themeVariables.xsize.label1]}
                      dColor={themeVariables.darkMode.alternative}
                    >
                      {item?.company_name}
                    </DMText>
                    <DMText
                      dColor={themeVariables.darkMode.alternative}
                      mx={4}
                      sx={[themeVariables.xsize.label1]}
                    >
                      ·
                    </DMText>
                    <DMText
                      dColor={themeVariables.darkMode.alternative}
                      sx={[themeVariables.xsize.label1]}
                    >
                      {timeForToday(new Date(item.published), false)}
                    </DMText>
                  </Flex>
                </Stack>
              </Flex>
              {idx !== 5 && (
                <Divider
                  color={themeVariables.lineDark.alternative(dark)}
                  sx={(theme) => ({
                    width: 'calc(100% - 64px)',
                    margin: 'auto',
                    [theme.fn.smallerThan('sm')]: {
                      width: 'calc(100% - 40px)',
                    },
                  })}
                />
              )}
            </Fragment>
          ))}
        </Stack>
      </Box>
      <BottomTab title={'기술 블로그 더 보기'} navigate={navigateTechBlog} />
    </Card>
  );
};

export default MainBlog;
