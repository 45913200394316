import { Divider, Flex, Text } from '@mantine/core';

import { IconChevronRight } from '@tabler/icons-react';
import { isDark } from '@/utils/utils';
import themeVariables from '@/styles/themeVariables';

const BottomTab = ({ title, navigate }) => {
  const { dark } = isDark();

  return (
    <>
      <Divider w={'100%'} color={themeVariables.lineDark.neutral(dark)} />
      <Flex
        sx={{
          cursor: 'pointer',
          margin: '16px 0px',
          gap: 4,
          justifyContent: 'center',
          alignItems: 'center',
          color: themeVariables.label.alternative,
          transition: '200ms all ease',
          ':hover': {
            color: dark
              ? themeVariables.fontColors.white
              : themeVariables.label.neutral,
          },
        }}
        onClick={navigate}
      >
        <Text weight={500} {...themeVariables.size.body1}>
          {title}
        </Text>
        <IconChevronRight size={20} color={themeVariables.label.alternative} />
      </Flex>
    </>
  );
};

export default BottomTab;
