import { createStyles } from '@mantine/core';
import themeVariables from '@/styles/themeVariables';

export const useMainStyles = createStyles((theme, dark: any = {}) => ({
  mainPageContainer: {
    maxWidth: 1280,
    margin: 'auto',
  },
  wrapper: {
    display: dark?.preKeyword ? 'initial' : 'none',
  },

  searchInput: {
    width: '100%',
    height: '38px !important',
    border: 'none',
    borderRadius: '0px',
    color: themeVariables.darkMode.normal(dark?.dark),
    fontSize: 28,
    fontWeight: 600,
    ':focus': {
      outline: 'none',
    },
    '::placeholder': {
      color: themeVariables.darkMode.alternative(dark?.dark),
      fontWeight: 700,
    },
    backgroundColor: 'transparent',
    lineHeight: '38px',
    [theme.fn.smallerThan('sm')]: {
      fontSize: 18,
      height: '28px !important',
    },
  },

  searchInputRightSection: {
    width: 140,
    [theme.fn.smallerThan('sm')]: {
      width: 60,
    },
  },

  searchInputKbd: {
    cursor: 'pointer',
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  keywordBadge: {
    cursor: 'pointer',
    padding: '8px 10px',
    borderRadius: 10,
    backgroundColor: dark?.dark ? 'none' : themeVariables.colors.light100,
    border: dark?.dark
      ? `1px solid ${themeVariables.line.dark}`
      : `1px solid ${themeVariables.colors.light100}`,
    transition: 'all ease 300ms',
    marginRight: 12,
    ':hover': {
      transform: 'translateY(-3px)',
    },
    [theme.fn.smallerThan('sm')]: {
      ':hover': {
        transform: 'none',
      },
      padding: '7px 8px',
    },
  },
}));
