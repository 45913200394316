'use client';

import { ComponentProps, ReactNode } from 'react';
import { Card as MantineCard, Sx } from '@mantine/core';

import { createStyles } from '@mantine/core';
import { isDark } from '@/utils/utils';
import themeVariables from '@/styles/themeVariables';

const createCardStyles = createStyles((_, dark: any) => ({
  linkCardFrame: {
    boxShadow: themeVariables.boxShadow.cardShadow,
    borderRadius: '16px',
    cursor: 'pointer',
    transition: 'all ease 300ms',
    '&:hover': {
      transform: 'translateY(-4px)',
      boxShadow: themeVariables.boxShadow.cardHoverShadow,
    },
  },

  noShadowLinkCardFrame: {
    border: `1.2px solid ${
      dark?.dark ? '#3a3a3a' : themeVariables.line.neutral
    }`,
    backgroundColor: dark?.dark ? '#202020' : '#fff',
    borderRadius: '16px',
    cursor: 'pointer',
    transition: 'all ease 300ms',
    '&:hover': {
      transform: 'translateY(-4px)',
    },
  },

  cardFrame: {
    boxShadow: themeVariables.boxShadow.cardShadow,
    borderRadius: '16px',
  },

  noShadowCardFrame: {
    border: `1.2px solid ${
      dark?.dark ? '#3a3a3a' : themeVariables.line.neutral
    }`,
    backgroundColor: dark?.dark ? '#202020' : '#fff',
    borderRadius: '16px',
    transition: 'all ease 300ms',
  },
}));

type Props = {
  children?: ReactNode | ReactNode[];
  isLink?: boolean;
  noShadow?: boolean;
  style?: Sx;
  props?: Pick<
    ComponentProps<'div'>,
    'onMouseEnter' | 'onMouseLeave' | 'onClick'
  >;
};

const Card = ({
  children,
  isLink = false,
  noShadow = false,
  style,
  props,
}: Props) => {
  const { dark } = isDark();
  const { classes } = createCardStyles({ dark });

  return isLink ? (
    <MantineCard
      className={
        noShadow ? classes.noShadowLinkCardFrame : classes.linkCardFrame
      }
      sx={[{ padding: 24 }, style]}
      {...props}
    >
      {children}
    </MantineCard>
  ) : (
    <MantineCard
      className={noShadow ? classes.noShadowCardFrame : classes.cardFrame}
      sx={[{ padding: 24 }, style]}
      {...props}
    >
      {children}
    </MantineCard>
  );
};

export default Card;
