import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import themeVariables from '@/styles/themeVariables';
import toast from 'react-hot-toast';

const Toast = {
  plain: (text) => {
    toast(text);
  },
  success: (text) => {
    toast.success(text);
  },
  error: (text) => {
    toast.error(text);
  },
  info: (text) => {
    toast(text, {
      icon: (
        <FontAwesomeIcon
          icon={faExclamationCircle}
          style={{
            height: 20,
            width: 20,
            color: themeVariables.colors.blue300,
            animation: 'pulse 0.3s linear 1',
          }}
        />
      ),
    });
  },
};

export default Toast;
