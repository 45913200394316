import {
  newsIndex,
  useGetSearchRecommendKeywords,
} from '@/features/Main/hook/api';
import {
  searchKeyword,
  searchTabAtom,
} from '@/features/Search/hook/search.state';
import { startTransition, useState } from 'react';

import Toast from '@/components/Toast';
import { mixpanelTrack } from '@/utils/mixpanel';
import { useDebouncedState } from '@mantine/hooks';
import { useRouter } from 'next/navigation';
import { useSetAtom } from 'jotai';

export const useKeywordsMethod = () => {
  const setKeyword = useSetAtom(searchKeyword);
  const setNewsIdx = useSetAtom(newsIndex);
  const setValue = useSetAtom(searchTabAtom);

  const { data } = useGetSearchRecommendKeywords();
  const { push } = useRouter();

  const handleKeyword = (item) => () => {
    if (!item) return Toast.info('검색어를 입력해주세요!');

    push(`/search?keyword=${item}`);

    setValue('전체');
    setKeyword(item);

    startTransition(() => {
      setNewsIdx(0);
      mixpanelTrack('MainPage_SearchBox_RecommendedTags', 'Clicked', {
        keyword: item,
      });
    });
  };

  return { data, handleKeyword };
};

export const useInputMethod = (setKeyword, close) => {
  const { push } = useRouter();

  const [hintSearch, setHintSearch] = useDebouncedState('', 30);
  const [preKeyword, setPreKeyword] = useState('');

  const setNewsIdx = useSetAtom(newsIndex);
  const setValue = useSetAtom(searchTabAtom);

  const closeBtn = () => {
    setPreKeyword('');
    setHintSearch('');
  };

  const changeKeyword = (value) => {
    setHintSearch(value);
    setPreKeyword(value);
    setKeyword(value);
  };

  const submitKeyword = () => {
    if (!preKeyword) return Toast.info('검색어를 입력해주세요!');

    if (close) close();

    push(`/search?keyword=${preKeyword}`);

    setNewsIdx(0);
    setValue('전체');

    mixpanelTrack('MainPage_SearchBox_Input', 'Searched', {
      keyword: preKeyword,
    });
  };

  return { preKeyword, hintSearch, closeBtn, changeKeyword, submitKeyword };
};
