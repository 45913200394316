import {
  Autocomplete,
  Box,
  Flex,
  Group,
  Space,
  ThemeIcon,
} from '@mantine/core';
import { forwardRef, useEffect } from 'react';

import DMText from '@/components/DMText';
import { IconSearch } from '@tabler/icons-react';
import dynamic from 'next/dynamic';
import { isDark } from '@/utils/utils';
import { searchKeyword } from '@/features/Search/hook/search.state';
import themeVariables from '@/styles/themeVariables';
import { useFocusWithin } from '@mantine/hooks';
import { useGetHints } from '@/features/Main/hook/api';
import { useInputMethod } from '@/features/Main/hook/useMethod';
import { useMainStyles } from '@/features/Main/hook/main.style';
import { useSetAtom } from 'jotai';

const MainKeywords = dynamic(
  () => import('@/features/Main/components/MainKeywords'),
  { ssr: false }
);

const MainInput = () => {
  return (
    <>
      <InputContainer close={null} />
      <Box
        w={'100%'}
        h={38}
        sx={(theme) => ({
          marginTop: 20,
          marginBottom: 64,
          [theme.fn.smallerThan('sm')]: {
            marginTop: 16,
            marginBottom: 32,
          },
        })}
      >
        <MainKeywords />
      </Box>
    </>
  );
};

export default MainInput;

export const InputContainer = ({ close, focus = false }) => {
  const { dark } = isDark();

  const setKeyword = useSetAtom(searchKeyword);

  const { ref } = useFocusWithin<HTMLInputElement>();
  const { preKeyword, hintSearch, changeKeyword, submitKeyword } =
    useInputMethod(setKeyword, close);

  const { classes } = useMainStyles({ dark, preKeyword });
  const { data: hints } = useGetHints(hintSearch);

  useEffect(() => {
    if (focus) {
      setTimeout(() => {
        ref.current.focus();
      }, 500);
    }
  }, []);

  return (
    <Flex
      align={'center'}
      pb={12}
      sx={() => ({
        transition: '0.3s all ease',
        borderBottom: `3.5px solid ${themeVariables.darkMode.neutral(dark)}`,
      })}
    >
      <IconSearch stroke={3.5} color={themeVariables.darkMode.neutral(dark)} />
      <Space w={12} />
      <Autocomplete
        ref={ref}
        autoFocus={focus}
        withinPortal
        iconWidth={70}
        w={'100%'}
        classNames={{
          input: classes.searchInput,
          rightSection: classes.searchInputRightSection,
          itemsWrapper: classes.wrapper,
        }}
        placeholder={'검색어를 입력해 주세요'}
        value={preKeyword}
        onChange={changeKeyword}
        itemComponent={forwardRef(AutoCompleteItem)}
        onItemSubmit={submitKeyword}
        data={hints?.keywords || []}
        limit={6}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            submitKeyword();
          }
        }}
      />
    </Flex>
  );
};

const AutoCompleteItem = (
  { value, ...others }: any,
  ref: React.Ref<HTMLInputElement>
) => {
  return (
    <div ref={ref} {...others}>
      <Group noWrap px={12}>
        <ThemeIcon radius={'xl'} bg={themeVariables.colors.light100}>
          <IconSearch
            size={13}
            stroke={2.5}
            color={themeVariables.fontColors.light}
          />
        </ThemeIcon>
        <DMText size={15} weight={500} dColor={themeVariables.darkMode.strong}>
          {value}
        </DMText>
      </Group>
    </div>
  );
};
